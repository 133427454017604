import React, { useCallback, useEffect, useState } from 'react'
import DeleteDialogue from './DeleteDialogue'
import { IconButton, Menu, MenuItem, MoreVerticalIcon, ToolTip } from '@papertrail/styleguide'
import { Filter } from 'src/types'
import SetDefaultDialogue from './SetDefaultFilter'

type Props = {
  accountid: string
  filter: Filter
  onComplete: () => void
}
export default function FilterActions(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [filter, setFilter] = useState(undefined)
  const [showDelete, setShowDelete] = useState(false)
  const [showDefault, setShowDefault] = useState(false)

  const handleEscapeKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowDelete(false)
      setShowDefault(false)
    }
  }, [])

  useEffect(() => {
    if (showDefault || showDelete) {
      document.addEventListener('keydown', handleEscapeKeyPress)
    } else {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [showDefault, showDelete, handleEscapeKeyPress])

  function openDeleteDialog(filter) {
    setShowDelete(true)
    setFilter(filter)
    handleClose()
  }
  function openDefaultDialog() {
    handleClose()
    setShowDefault(true)
  }
  function onDelete() {
    setShowDelete(false)
    props.onComplete()
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVerticalIcon htmlColor="black" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <ToolTip
          title={
            props.filter.name === 'All Records'
              ? 'You cannot delete the All Records filter'
              : props.filter.default
              ? 'You cannot delete the default filter'
              : 'Delete'
          }>
          <span>
            <MenuItem disabled={props.filter.name === 'All Records' || props.filter.default} onClick={openDeleteDialog}>
              Delete filter
            </MenuItem>
          </span>
        </ToolTip>

        <ToolTip title={props.filter.default ? 'Current default' : 'Set default filter'}>
          <span>
            <MenuItem disabled={props.filter.default} data-testid="default_button" onClick={openDefaultDialog}>
              Set as default filter
            </MenuItem>
          </span>
        </ToolTip>
      </Menu>
      {filter && (
        <DeleteDialogue
          show={showDelete}
          filter={props.filter}
          accountid={props.accountid}
          onCancel={() => setShowDelete(false)}
          onComplete={() => onDelete()}
        />
      )}
      <SetDefaultDialogue
        show={showDefault}
        filter={props.filter}
        accountid={props.accountid}
        onCancel={() => setShowDefault(false)}
        onComplete={() => onDelete()}
      />
    </div>
  )
}
