import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import * as yup from 'yup'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  FlexRow,
  Formik,
  FormFieldDatePicker,
  Box,
  Form,
  ToolTip,
  InfoIcon,
  useFormikContext
} from '@papertrail/styleguide'
import dayjs from 'dayjs'
import { RRule } from 'rrule'
import './CustomDatePicker.css'
import { useApiPost } from '@papertrail/web3-utils'

type Props = {
  accountid: string
  frequencyRule: any
}

const FrequencyPreview = ({ accountid, frequencyRule }: Props) => {
  const [previewDates, setPreviewDates] = useState([])
  const [firstDate, setFirstDate] = useState(null)
  const { values }: any = useFormikContext()
  const isMountedRef = useRef(true)

  const [, postPreview] = useApiPost(`/accounts/${accountid}/frequencies/schedule/preview`, (data) => {
    setPreviewDates(JSON.parse(data))
  })

  const initialValues = { inspectionDateField: dayjs(firstDate) }

  const validationSchema = yup.object({
    inspectionDateField: yup.date().required('Please choose a date')
  })

  const getPreviewDates = (startDate: Date) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD')
    const payload = {
      start_date: formattedStartDate,
      frequency_rule: values.frequencyRule,
      result_count: 10
    }
    try {
      postPreview(payload)
    } catch (error) {
      console.error('Error fetching preview dates:', error)
    }
  }

  useEffect(() => {
    if (frequencyRule && firstDate === null && isMountedRef.current) {
      const currentDate = new Date()
      setFirstDate(currentDate)
      getPreviewDates(currentDate)
    }
    return () => {
      isMountedRef.current = false
    }
  }, [frequencyRule, firstDate])

  const disabledDates = (date) => {
    if (!frequencyRule) {
      return false
    }
    if (frequencyRule === 'FREQ=WEEKLY') {
      return false
    }

    const ruleObj = new RRule(RRule.parseString(frequencyRule))
    const selectedDaysOfWeek = ruleObj.options.byweekday || []

    if (selectedDaysOfWeek.length > 0) {
      const jsDaysOfWeek = selectedDaysOfWeek.map((rruleDay) => (rruleDay + 1) % 7)
      const dayOfWeek = new Date(date).getDay()
      return !jsDaysOfWeek.includes(dayOfWeek)
    }

    return false
  }

  return (
    <div>
      <FlexRow justifyContent={''}>
        <Box
          sx={{
            display: 'flex',
            paddingLeft: '0px',
            paddingBottom: '0px',
            paddingTop: '0px',
            fontStyle: 'bold',
            alignItems: 'center',
            fontSize: 'large',
            color: 'black'
          }}>
          {' '}
          Preview
        </Box>
        <div style={{ display: 'flex', marginLeft: '5px', alignItems: 'center' }}>
          <ToolTip title={'The preview shows the next inspection dates'}>
            <InfoIcon />
          </ToolTip>
        </div>
      </FlexRow>
      <Divider style={{ marginTop: '20px' }} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => console.log(values)}>
        <Form>
          <Box sx={{ paddingLeft: '20px', paddingTop: '20px', fontWeight: 'bold' }}>
            <FormFieldDatePicker
              name="inspectionDateField"
              label="Choose date of the inspection"
              shouldDisableDate={disabledDates}
              onDateChange={(date) => {
                setFirstDate(date)
                getPreviewDates(date)
              }}
            />
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <Box sx={{ paddingLeft: '20px' }}> Next check date</Box>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(previewDates) && previewDates.length > 0 ? (
                previewDates.map((date, index) => {
                  const formattedDate = dayjs(date).format('ddd, D MMMM YYYY')
                  return (
                    <TableRow key={index}>
                      <TableCell>{formattedDate}</TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell>No preview dates available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Form>
      </Formik>
    </div>
  )
}

export default FrequencyPreview
