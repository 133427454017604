import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonPrimary,
  ButtonSecondary
} from '@papertrail/styleguide'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  type: string
}

export default function ConfirmDialog(props: Props) {
  const { onCancel, onConfirm, type } = props
  return (
    <Dialog open={true}>
      <DialogTitle>Confirm</DialogTitle>
      {type !== 'checklist' && <DialogContent>Are you sure you want to delete this {type}?</DialogContent>}
      {type === 'checklist' && (
        <DialogContent>Are you sure you want to exit this {type}? All changes will be lost.</DialogContent>
      )}
      <DialogActions>
        <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
        <ButtonPrimary onClick={onConfirm} data-testid="confirm">
          Confirm
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}
