import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApiGet, useTitle } from '@papertrail/web3-utils'
import DeleteDialogue from './DeleteDialogue'
import EditDialogue from './EditDialogue'
import MergeDialogue from './MergeDialogue'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  AlertWarning,
  Box,
  ButtonPrimary,
  ButtonSecondary,
  ContentHeader,
  Loading,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TagIcon,
  NoData,
  Checkbox,
  FlexRow,
  ContentNonScrolling,
  ContentWrapper,
  BasicPagination
} from '@papertrail/styleguide'

type Params = {
  accountid: string
}

function tagsMapper(data) {
  const tags = data.data.map((n) => {
    return { id: n.id, name: n.name, recordCount: n.record_count.data.record_count }
  })
  const meta = data.meta
  const tagsData = { tags, meta }
  return tagsData
}
export default function TagsManager() {
  const { accountid } = useParams() as Params
  const [tag, setTag] = useState(undefined)
  const [tagState, loadTags] = useApiGet<any>(`/accounts/${accountid}/tags`, tagsMapper)

  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showMerge, setShowMerge] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [currentPage, setPage] = useState<number>(1)

  const { t } = useTranslation(['tools', 'global'])

  useTitle(t('tagManager'))
  const history = useHistory()

  function openDeleteDialog(tag) {
    setShowDelete(true)
    setTag(tag)
  }
  function openEditDialogue(tag) {
    setShowEdit(true)
    setTag(tag)
  }

  function openMergeDialogue() {
    setShowMerge(true)
  }

  function onDeleteCompleted() {
    setShowDelete(false)
    loadTags({ include: 'record_count', limit: 50, page: currentPage })
  }

  function onEditCompleted() {
    setShowEdit(false)
    loadTags({ include: 'record_count', limit: 50, page: currentPage })
  }

  function onMergeCompleted() {
    setShowMerge(false)
    setSelected([])
    loadTags({ include: 'record_count', limit: 50, page: currentPage })
  }

  function getSelectedTags() {
    if (tagState.isLoaded) {
      return tagState.data.tags.filter((tag) => selected.includes(tag.id))
    } else {
      return []
    }
  }

  useEffect(() => {
    loadTags({ include: 'record_count', limit: 50, page: currentPage })
  }, [])

  const isSelected = (tagid) => selected.indexOf(tagid) !== -1

  const handleClick = (event: React.MouseEvent<unknown>, tagid) => {
    const selectedIndex = selected.indexOf(tagid)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, tagid)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const changePage = (event, page) => {
    setPage(page)
    loadTags({ include: 'record_count', limit: 50, page: page })
  }

  const goToTaggedRecords = (tag) => {
    history.push(`/accounts/${accountid}/folders?tag=${tag}`)
  }

  return (
    <ContentWrapper>
      <ContentHeader>{t('tagManager')}</ContentHeader>

      <ContentNonScrolling>
        <FlexRow justifyContent={'space-between'}>
          <h3>{t('tagManager')}</h3>
          <ButtonPrimary onClick={() => openMergeDialogue()} disabled={selected.length < 2}>
            {t('mergeTag')}
          </ButtonPrimary>
        </FlexRow>
        <p>{t('tagInfoText')}</p>
        {tagState.isLoading && <Loading />}
        {tagState.isError && <AlertWarning>{t('alertWarning')}</AlertWarning>}
        {tagState.isLoaded && (
          <>
            <Box overflow={'auto'}>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label={t('tagTable')}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('tag')}</TableCell>
                    <TableCell>{t('recordCount')}</TableCell>
                    <TableCell align="right">{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tagState.isLoaded &&
                    tagState.data.tags.map((tag) => {
                      const isItemSelected = isSelected(tag.id)
                      return (
                        <TableRow
                          key={tag.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          selected={isItemSelected}>
                          <TableCell scope="row">
                            <Checkbox
                              color="primary"
                              inputProps={{
                                'aria-label': t('selectTags')
                              }}
                              onClick={(event) => handleClick(event, tag.id)}
                              checked={isItemSelected}
                              value={tag.name}
                            />
                            {tag.name}
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => goToTaggedRecords(tag.name)}>
                            {tag.recordCount}
                          </TableCell>
                          <TableCell align="right">
                            <ButtonSecondary onClick={() => openDeleteDialog(tag)} disabled={selected.length > 0}>
                              {t('global:delete')}
                            </ButtonSecondary>
                            <ButtonPrimary onClick={() => openEditDialogue(tag)} disabled={selected.length > 0}>
                              {t('global:edit')}
                            </ButtonPrimary>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </Box>
            {tagState.isLoaded && tagState.data.tags.length > 0 && (
              <FlexRow justifyContent={'center'}>
                <BasicPagination
                  count={tagState.data.meta.pagination.total_pages}
                  page={tagState.data.meta.pagination.current_page}
                  handleChange={changePage}
                />
              </FlexRow>
            )}
          </>
        )}
        {tagState.isLoaded && tagState.data.tags.length === 0 && <NoData icon={TagIcon} text={t('errorNoTags')} />}
      </ContentNonScrolling>

      {tag && (
        <DeleteDialogue
          show={showDelete}
          accountid={accountid}
          tag={tag}
          onCancel={() => setShowDelete(false)}
          onComplete={() => onDeleteCompleted()}
        />
      )}

      {tag && (
        <EditDialogue
          show={showEdit}
          accountid={accountid}
          tag={tag}
          onCancel={() => setShowEdit(false)}
          onComplete={() => onEditCompleted()}
        />
      )}

      <MergeDialogue
        show={showMerge}
        accountid={accountid}
        tags={getSelectedTags()}
        onCancel={() => setShowMerge(false)}
        onComplete={() => onMergeCompleted()}
      />
    </ContentWrapper>
  )
}
