import {
  Alert,
  Box,
  ButtonPrimary,
  ContentHeader,
  ContentNonScrolling,
  ContentWrapper,
  FlexRow,
  NoData,
  SettingsTeamIcon,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery
} from '@papertrail/styleguide'
import { useApiGet } from '@papertrail/web3-utils'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EditChecklist from './EditChecklist'
import CreateChecklist from './CreateChecklist'

type Params = {
  accountid: string
}

const checklistMapper = (data) => {
  const checklists = data.data.map((n) => {
    return { id: n.id, name: n.name, description: n.description, sections: n.sections, meta: n.meta }
  })
  return checklists
}

const ChecklistEditor = () => {
  const { accountid } = useParams() as Params
  const [checklistState, loadChecklists] = useApiGet<any>(`/accounts/${accountid}/checklists`, checklistMapper)
  const [globalChecklistState, loadGlobalChecklists] = useApiGet<any>(`/checklists`, checklistMapper)
  const [availableChecklists, setAvailableChecklists] = useState<boolean>(false)
  const [checklist, setChecklist] = useState(undefined)
  const [showEdit, setShowEdit] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const { t } = useTranslation(['tools', 'global'])
  const [showSnackbar, setShowSnackbar] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)')
  const addNewChecklist = () => {
    setShowCreate(true)
  }

  const onCreateCompleted = () => {
    setShowCreate(false)
    setChecklist(undefined)
    loadChecklists({ limit: 1000 })
  }

  useEffect(() => {
    if (accountid) {
      loadChecklists({ limit: 1000 })
      loadGlobalChecklists({ limit: 1000 })
    }
  }, [accountid])

  const openEditDialogue = (checklist) => {
    setShowEdit(true)
    setChecklist(checklist)
  }

  const onEditCompleted = () => {
    setShowSnackbar(true)
    setShowEdit(false)
    setChecklist(undefined)
    loadChecklists({ limit: 1000 })
  }

  return (
    <ContentWrapper>
      <ContentHeader>
        {'Settings > Checklists'}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setShowSnackbar(false)
          }}>
          <Alert data-testid="success-message" severity="success">
            Your checklist has been successfully saved!
          </Alert>
        </Snackbar>
      </ContentHeader>
      <ContentNonScrolling>
        <FlexRow justifyContent={'space-between'} sx={isMobile ? { paddingRight: '0px' } : { paddingRight: '120px' }}>
          <h3>{t('Checklists')}</h3>
          {!availableChecklists && (
            <ButtonPrimary onClick={() => addNewChecklist()}>
              {isMobile ? '+ Checklist' : 'Add New Checklist'}
            </ButtonPrimary>
          )}
        </FlexRow>
        <p>{t('You can manage and customise your own checklists for this account.')}</p>
        <Box overflow="auto" sx={{ borderTop: 'solid 1px #E4E7EB' }}>
          <Table stickyHeader>
            <TableHead sx={{ borderBottom: 'solid 2px #E4E7EB' }}>
              <TableRow>
                <TableCell width={1}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '-17px' }}>
                    <Box
                      onClick={() => setAvailableChecklists(!availableChecklists)}
                      style={{
                        paddingRight: '16px',
                        paddingLeft: '8px',
                        paddingBottom: '20px',
                        paddingTop: '16px',
                        cursor: 'pointer'
                      }}
                      sx={
                        !availableChecklists
                          ? { fontWeight: 'bold', borderBottom: 'solid 2px #007AFF' }
                          : { fontWeight: 'normal' }
                      }>
                      {t('Added Checklists')}
                      {checklistState && checklistState.isLoaded && ` (${checklistState.data.length})`}
                    </Box>
                    {/* <Box
                      onClick={() => setAvailableChecklists(!availableChecklists)}
                      style={{ paddingBottom: '20px', paddingLeft: '8px', paddingTop: '16px', cursor: 'pointer' }}
                      sx={
                        availableChecklists
                          ? { fontWeight: 'bold', borderBottom: 'solid 2px #007AFF' }
                          : { fontWeight: 'normal' }
                      }>
                      {t('Available Checklists')}
                      {globalChecklistState &&
                        globalChecklistState.isLoaded &&
                        ` (${globalChecklistState.data.length})`}
                    </Box> */}
                  </Box>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!availableChecklists &&
                checklistState.isLoaded &&
                checklistState.data.map((checklist) => {
                  return (
                    <TableRow key={checklist.id}>
                      <TableCell width="100%">
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ paddingBottom: '8px', fontWeight: 'bold' }}>{checklist.name}</Box>
                          <Box>{checklist.description || ''}</Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ paddingRight: '120px' }}>
                        <FlexRow justifyContent="left">
                          <ButtonPrimary data-testid={checklist.name} onClick={() => openEditDialogue(checklist)}>
                            Edit
                          </ButtonPrimary>
                        </FlexRow>
                      </TableCell>
                    </TableRow>
                  )
                })}

              {availableChecklists &&
                globalChecklistState.isLoaded &&
                globalChecklistState.data.map((checklist) => {
                  return (
                    <TableRow key={checklist.id}>
                      <TableCell width="100%">
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ paddingBottom: '8px', fontWeight: 'bold' }}>{checklist.name}</Box>
                          <Box sx={{ maxWidth: '1000px' }}>{checklist.description || ''}</Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ paddingRight: '120px' }}>
                        <FlexRow justifyContent="left"></FlexRow>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Box>
      </ContentNonScrolling>
      {checklistState.isLoaded && checklistState.data.length === 0 && (
        <NoData icon={SettingsTeamIcon} text="There are no available checklists in this account" />
      )}

      {checklist && (
        <EditChecklist
          show={showEdit}
          accountid={accountid}
          checklist={checklist}
          onCancel={() => setShowEdit(false)}
          onComplete={() => onEditCompleted()}
        />
      )}
      <CreateChecklist
        show={showCreate}
        accountid={accountid}
        onCancel={() => setShowCreate(false)}
        onComplete={() => onCreateCompleted()}
      />
    </ContentWrapper>
  )
}

export default ChecklistEditor
